
import Vue from 'vue';

// import Icon from '@/components/base/icon/';

export default Vue.extend({
  name: 'AdminSide',
  components: {
    // Icon,
  },
  data() {
    return {
      router: true,
      sideMenu: [
        // {
        //   name: '发布文章',
        //   path: '/admin/write',
        //   icon: 'el-icon-edit',
        // },
        // {
        //   name: '文章管理',
        //   path: '/admin/article',
        //   icon: 'el-icon-edit-outline',
        // },
        // {
        //   name: '分类管理',
        //   path: '/admin/category',
        //   icon: 'el-icon-c-scale-to-original',
        // },
        // {
        //   name: '标签管理',
        //   path: '/admin/tag',
        //   icon: 'el-icon-edit-outline',
        // },
        // {
        //   name: '评论管理',
        //   path: '/admin/comment',
        //   icon: 'el-icon-edit',
        // },
        // {
        //   name: '用户管理',
        //   path: '/admin/user',
        //   icon: 'el-icon-s-custom',
        // },
        // {
        //   name: '角色管理',
        //   path: '/admin/role',
        //   icon: 'el-icon-user',
        // },
        // {
        //   name: '资源管理',
        //   path: '/admin/resource',
        //   icon: 'el-icon-tickets',
        // },
        {
          name: '品牌馆分类管理',
          path: '/admin/menu',
          icon: 'el-icon-menu',
        },
      ],
    };
  },
});
