
import Vue from 'vue';

import AdminSide from '@/components/framework/admin-side.vue';
import AdminContent from '@/components/framework/admin-content.vue';

// import SignIn from '@/components/kit/sign-in/index.js';
// import SignUp from '@/components/kit/sign-up/index.js';

export default Vue.extend({
  name: 'AdminLayout',
  components: {
    AdminSide,
    AdminContent,

    // SignIn,
    // SignUp,
  },
});
