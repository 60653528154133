
import Vue from 'vue';
// import Icon from '@/components/base/icon/';
import LoginControl from './login-control.vue';

const prefixCls = 'app-menu';

export default Vue.extend({
  name: 'AppMenu',
  components: {
    // Icon,
    LoginControl,
  },
  props: {
    theme: {
      type: String,
      default: 'white',
    },
  },
  data() {
    return {
      isShowMenu: false,
      navList: [
        // {
        //   name: '首页',
        //   path: '/',
        // },
        // {
        //   name: '技术文章',
        //   path: '/article',
        // },
        // {
        //   name: '资源列表',
        //   path: '/resource',
        // },
        // {
        //   name: '豆瓣电影',
        //   path: '/movie',
        // },
        // {
        //   name: '电子书',
        //   path: '/ebook',
        // },
        // {
        //   name: '后台管理',
        //   path: '/admin',
        // },
      ],
    };
  },
  computed: {
    classes() {
      return [
        `${prefixCls}`,
        `${prefixCls}-${this.theme}`,
        {
          [`${prefixCls}-show`]: this.isShowMenu,
        },
      ];
    },
  },
  methods: {
    /**
     * @desc 小屏下 切换显示
     */
    handleToggleMenu() {
      this.isShowMenu = !this.isShowMenu;
    },
  },
});
