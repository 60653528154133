
import Vue from 'vue';

import AdminHeader from '@/components/framework/admin-header.vue';

export default Vue.extend({
  name: 'AdminContent',
  components: {
    AdminHeader,
  },
});
