
import Vue from 'vue';
import AppMenu from '@/components/framework/app-menu.vue';

export default Vue.extend({
  name: 'AdminHeader',
  components: {
    AppMenu,
  },
});
