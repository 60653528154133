
import Vue from 'vue';

export default Vue.extend({
  name: 'Icon',
  props: {
    type: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'inherit',
    },
    size: {
      type: [Number, String],
      default: 'inherit',
    },
  },
  computed: {
    styles() {
      return {
        fontSize: typeof this.size === 'number' ? `${this.size}px` : this.size,
        color: this.color,
      };
    },
  },
  methods: {
    handleClick(event) {
      this.$emit('click', event);
    },
  },
});
