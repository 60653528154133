
import Vue from 'vue';

// import Icon from '@/components/base/icon/';
import Vuex from 'vuex';

import Zbtn from '@/components/base/btn';
import ZbtnGroup from '@/components/base/btn-group/';
import ConcaveRadiusBox from '@/components/kit/concave-radius-box/';

import { MyLocalStorage } from '@/utils/storage';
import { login } from '@/utils';

const { mapActions } = Vuex;

export default Vue.extend({
  name: 'LoginControl',
  components: {
    Zbtn,
    ZbtnGroup,
    ConcaveRadiusBox,
  },
  props: {
    theme: String,
  },
  data() {
    return {
      loading: true,
      userInfo: null,
    };
  },
  computed: {
    // ...mapGetters('common', {
    //   userInfo: 'getUserInfo',
    // })
  },
  mounted() {
    this.loading = false;
    const userInfo = MyLocalStorage.get('userInfo');
    if (userInfo) {
      this.userInfo = userInfo.userInfo;
      console.log(userInfo);
    }
  },
  methods: {
    ...mapActions({
      toggleSignUpModal: 'common/toggleSignUpModal',
      toggleSignInModal: 'common/toggleSignInModal',
    }),

    /**
     * @desc 登录
     */
    handleSignIn() {
      login();

      // return;
      // this.toggleSignInModal(true);
      // this.toggleSignUpModal(false);
    },

    /**
     * @desc 注册
     */
    handleSignUp() {
      this.toggleSignInModal(false);
      this.toggleSignUpModal(true);
    },

    /**
     * @desc 退出登录
     */
    handleSignOut() {
      // await this.$myApi.logout.create();
      sessionStorage.clear();
      localStorage.clear();
      console.log(this.$cookies, '$cookies');
      this.$cookies.remove('sso.jd.com', '/', '.jd.com');
      this.$store.dispatch('common/changeUserInfo', null);
      this.$store.dispatch('common/changeToken', '');
      location.reload();
    },
  },
});
