
import Vue from 'vue';

export default Vue.extend({
  name: 'CancaveRadiusBox',
  props: {
    size: {
      type: [String, Number],
      default: '100px',
    },
    theme: {
      type: String,
      default: 'white',
    },
  },
  computed: {
    styles() {
      return {
        height: typeof this.size === 'number' ? `${this.size}px` : this.size,
        width: typeof this.size === 'number' ? `${this.size}px` : this.size,
      };
    },
  },
});
