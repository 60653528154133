import { render, staticRenderFns } from "./admin-header.vue?vue&type=template&id=6f535416&scoped=true"
import script from "./admin-header.vue?vue&type=script&lang=ts"
export * from "./admin-header.vue?vue&type=script&lang=ts"
import style0 from "./admin-header.vue?vue&type=style&index=0&id=6f535416&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f535416",
  null
  
)

export default component.exports